import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

type QuickLinkCardProps = {
  title: string;
  buttonText: string;
  buttonLink?: string;
};

export const QuickLinkCard = ({
  title,
  buttonText,
  buttonLink,
}: QuickLinkCardProps): React.ReactElement => {
  return (
    <Grid item xs={12} sm={9} md={6} lg={4} xl={3} sx={{ paddingBottom: 4 }}>
      <Card className="standardCard quickLinks">
        <Box sx={{ float: "left" }}>
          <CardContent>
            <Typography
              variant="body1"
              color="text.primary"
              sx={{ fontWeight: 500 }}
            >
              {title}
            </Typography>
          </CardContent>
        </Box>
        <CardActions sx={{ display: "block" }}>
          <Button
            role="button"
            disabled={buttonText !== "Go"}
            variant="contained"
            className="quick-link-btn"
            onClick={() => window.open(buttonLink, "_blank")}
          >
            {buttonText}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
