import { useUserContext } from "../../contexts/user-context";

export const MyToken = () => {
  const { accessToken } = useUserContext();

  return (
    <div style={{ fontSize: "24px", padding: "10px" }}>
      Access token:<p>{accessToken}</p>
    </div>
  );
};
