import React from "react";
import { InputLabel } from "@mui/material";

type LabelProps = {
  id?: string;
  htmlFor: string;
  label: string;
  error?: string | undefined;
};

export const Label: React.FC<LabelProps> = ({ id, htmlFor, label, error }) => (
  <InputLabel
    htmlFor={htmlFor}
    id={id}
    sx={{
      textAlign: "left",
      fontSize: "14px",
      marginBottom: "3px",
      ...(error && { color: "#E3022C" }),
    }}
  >
    {label}
  </InputLabel>
);
