import { ReactElement, createContext, useContext, useState } from "react";
import { ErrorComponent } from "../components/ErrorComponent";

const ErrorContext = createContext({
  handleError: (message: string) => {
    return;
  },
});

export const useError = () => useContext(ErrorContext);

export const ErrorProvider = ({ children }: { children: ReactElement }) => {
  const [error, setError] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  const handleError = (message: string) => {
    setError(message);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };

  return (
    <ErrorContext.Provider value={{ handleError }}>
      {children}
      <ErrorComponent open={open} handleClose={handleClose} message={error} />
    </ErrorContext.Provider>
  );
};
