import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IActionItems, mapLevel } from "../../types/action-item";

type ActionsCardProps = {
  actionItem: IActionItems;
  isActionPlan: boolean;
  ButtonComponent: ReactNode;
};

export const ActionsCard = ({
  actionItem,
  isActionPlan,
  ButtonComponent,
}: ActionsCardProps): React.ReactElement => {
  return (
    <Card
      sx={{
        width: "100%",
        marginBottom: "10px",
        borderRadius: "12px",
        border: "1px solid #F3F4F6",
        boxShadow: "none",
        padding: "16px 24px",
        display: "flex",
        background: isActionPlan ? "#fff" : "#F3F4F6",
      }}
    >
      <CardContent sx={{ padding: 0, flex: 10 }}>
        <Typography
          variant="body2"
          className={mapLevel[actionItem.standardLevel].toLowerCase()}
          sx={{
            fontSize: "12px",
            color: "#191C1F",
            display: "inline-block",
            marginRight: "10px",
          }}
        >
          {mapLevel[actionItem.standardLevel]}
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "#191C1F", display: "inline-block", fontWeight: "500" }}
        >
          {actionItem.actionText}
        </Typography>
        <Typography variant="body1" sx={{ color: "#191C1F", marginTop: "5px" }}>
          {actionItem.actionDescription}
        </Typography>
      </CardContent>
      <CardActions sx={{ flex: 1, flexDirection: "column" }}>
        {ButtonComponent}
        <Button
          role="button"
          variant="contained"
          sx={{
            borderRadius: "25px",
            fontSize: "16px",
            paddingTop: "6px",
            paddingBottom: "6px",
            border: "1px solid #6b7280",
            background: "transparent",
            boxShadow: "none",
            color: "#374151",
            "&:hover": {
              background: "#E5E7EB",
              borderColor: "#6B7280",
              boxShadow: "none",
            },
          }}
          endIcon={<ArrowForwardIosIcon />}
          onClick={() => window.open(actionItem.link, "_blank")}
        >
          Resources
        </Button>
      </CardActions>
    </Card>
  );
};
