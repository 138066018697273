import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import informationBannerDesktop from "../../assets/images/information-desktop.png";
import informationBannerMobile from "../../assets/images/information-mobile.png";
import informationBannerIpad from "../../assets/images/information-ipad.png";
import CloseIcon from "@mui/icons-material/Close";
import { Paths } from "../../types/app";
import { StandardsCard } from "../../components/StandardsCard";
import { theme } from "../../assets/theme";
import { LinearProgressWithLabel } from "../../components/LinearProgress";
import { useStandards } from "../../contexts/StandardsProvider";
import { useUserContext } from "../../contexts/user-context";
import { QuickLinks } from "../../components/QuickLinks";

export default function ActionPlanHomePage() {
  const { submittedStandards, unsubmittedStandards, refreshData } =
    useStandards();
  const navigate = useNavigate();
  const userContext = useUserContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  const [showInfoBanner, setShowInfoBanner] = useState(true);

  useEffect(() => {
    const item = localStorage.getItem("showInfoBanner");
    if (item) {
      setShowInfoBanner(JSON.parse(item));
    }
    userContext.set({
      selectedStandard: undefined,
    });
    refreshData();
  }, [refreshData, userContext]);

  const hideInformationBanner = () => {
    localStorage.setItem("showInfoBanner", JSON.stringify(false));
    setShowInfoBanner(false);
  };

  return (
    <Box
      component="main"
      sx={{ flex: 1, m: isMobile ? "15px 15px 30px 15px" : 5 }}
    >
      {showInfoBanner ? (
        <Box>
          <Box sx={{ position: "relative" }}>
            <Box
              component="img"
              alt="Information banner"
              src={
                isMobile
                  ? informationBannerMobile
                  : isIpad
                    ? informationBannerIpad
                    : informationBannerDesktop
              }
              sx={{ width: "100%" }}
            />
          </Box>
          <IconButton
            size="medium"
            onClick={hideInformationBanner}
            aria-label="Close Information banner"
            sx={{
              position: "absolute",
              right: isMobile ? "8px" : "30px",
              top: isMobile ? "8px" : "30px",
              border: "1px solid #E5E7EB",
              background: "#fff",
              borderRadius: "8px",
              "&:hover": {
                background: "#fff",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      ) : null}
      <div>
        {unsubmittedStandards.length > 0 ? (
          <div>
            <Typography variant="h2" className="ap-home-page-heading margnT40">
              Self Assessments
            </Typography>
            <Grid container>
              {unsubmittedStandards.map((eachStandard, i) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={9}
                    md={6}
                    lg={4}
                    xl={3}
                    key={i}
                    sx={{ paddingBottom: 4 }}
                  >
                    <StandardsCard
                      standard={eachStandard}
                      ButtonComponent={
                        <Button
                          onClick={() =>
                            navigate(Paths.QUESTIONNAIRE, {
                              state: { standard: eachStandard },
                            })
                          }
                          role="button"
                          variant="contained"
                          className="standards-btn"
                        >
                          Take the Assessment
                        </Button>
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        ) : null}
        {submittedStandards.length > 0 ? (
          <div>
            <Typography variant="h2" className="ap-home-page-heading margnT40">
              Your Action Plans
            </Typography>
            <Grid container>
              {submittedStandards.map((eachStandard, i) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={9}
                    md={6}
                    lg={4}
                    xl={3}
                    key={i}
                    sx={{ paddingBottom: 4 }}
                  >
                    <StandardsCard
                      standard={eachStandard}
                      ButtonComponent={
                        <Box sx={{ display: "flex", width: "100%" }}>
                          <LinearProgressWithLabel
                            value={eachStandard.computedScore * 100}
                          />
                          <Button
                            role="button"
                            variant="contained"
                            className="standards-btn see-plan-btn"
                            onClick={() =>
                              navigate(
                                Paths.ACTION_PLAN_HOME_PAGE +
                                  "/" +
                                  eachStandard.id,
                              )
                            }
                          >
                            See Plan
                          </Button>
                        </Box>
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        ) : null}
        <QuickLinks />
      </div>
    </Box>
  );
}
