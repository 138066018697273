import { Navigate, Outlet, useLocation } from "react-router";
import { useUserContext } from "../contexts/user-context";
import { Paths } from "../types/app";

export const LoginProtect = () => {
  const userContext = useUserContext();
  const location = useLocation();

  if (!userContext.accessToken) {
    return (
      <Navigate to={Paths.LOGIN} state={{ redirectTo: location.pathname }} />
    );
  }

  return (
    <>
      <Outlet />
    </>
  );
};
