import { useState } from "react";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SidePanel } from "../../components/SideInfoPanel";
import { TwoColumnLayout } from "../../components/TwoColumnLayout";
import { Grid, MenuItem, SelectChangeEvent } from "@mui/material";
import { BootstrapInput } from "../../components/BootstrapInput";
import { useNavigate } from "react-router";
import { AppError, Paths } from "../../types/app";
import { CompanyFormData } from "../../types/user";
import { Label } from "../../components/Label";
import dropdownOptions from "./dropdown.json";
import { validationSchema } from "./validation-schema";
import { linkUserCompany } from "../../services/user-service";
import { useUserContext } from "../../contexts/user-context";
import { StyledSelect } from "../../components/StyledSelect";
import { Header } from "../../components/Header";
import { useError } from "../../contexts/ErrorContext";

export default function OrganisationDetails() {
  const companySizeOptions = dropdownOptions.companySize;
  const industriesOptions = dropdownOptions.industries;
  const navigate = useNavigate();
  const { handleError } = useError();
  const userContext = useUserContext();
  const org = userContext.user?.org;
  const [errors, setErrors] = useState<Partial<CompanyFormData>>({});
  const [formData, setFormData] = useState<CompanyFormData>({
    companyName: org?.name ?? "",
    companySize: org?.size ?? "",
    industry: org?.industry ?? "",
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (org !== undefined && org !== null) {
      navigate(Paths.ACTION_PLAN_HOME_PAGE);
    } else {
      const isValid = await validate();
      if (isValid) {
        try {
          const user = await linkUserCompany(userContext.user!.id, formData);
          userContext.set({ user });
          navigate(Paths.ACTION_PLAN_HOME_PAGE);
        } catch (e) {
          const error = e as AppError;
          if (error && error.message) {
            handleError(error.message);
          }
        }
      }
    }
  };

  const validate = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors: Partial<CompanyFormData> = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path as keyof CompanyFormData] = error.message;
        });
      }
      setErrors(validationErrors);
      return false;
    }
  };

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<unknown>,
  ) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <TwoColumnLayout
      leftChild={<SidePanel />}
      rightChild={
        <>
          <Header emailId={userContext.user?.emailId} />
          <Box
            sx={{
              my: { xl: 30, l: 30, md: 30, sm: 29, xs: 15 },
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h4"
              sx={{ fontWeight: 500, mb: 2 }}
            >
              Organisation Details
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{
                mt: 4,
                width: { xl: 480, lg: 480, md: 480, sm: 370, xs: 345 },
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Label
                    htmlFor="companyName"
                    label="What is the name of your company?"
                    error={errors.companyName}
                  />
                  <BootstrapInput
                    required
                    id="companyName"
                    disabled={org !== undefined && org !== null}
                    fullWidth
                    name="companyName"
                    autoComplete="companyName"
                    autoFocus
                    error={!!errors.companyName}
                    value={formData.companyName}
                    onChange={handleChange}
                  />
                  {errors.companyName && (
                    <Label
                      id="companySize"
                      htmlFor="companyName"
                      error={errors.companyName}
                      label={errors.companyName.toString()}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Label
                    id="companySize"
                    htmlFor="companySize"
                    label="What is your company size?"
                    error={errors.companySize}
                  />
                  <StyledSelect
                    labelId="companySize"
                    id="companySize"
                    name="companySize"
                    disabled={org !== undefined && org !== null}
                    value={formData.companySize}
                    label="What is your company size?"
                    fullWidth
                    error={!!errors.companySize}
                    onChange={handleChange}
                    inputProps={{
                      "aria-labelledby": "companySize",
                      "data-testid": "companySize-label",
                    }}
                  >
                    {companySizeOptions.map((option, i) => (
                      <MenuItem value={option} key={i} data-test-id={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                  {errors.companySize && (
                    <Label
                      htmlFor="companySize"
                      error={errors.companySize}
                      label={errors.companySize.toString()}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Label
                    id="industry-label"
                    htmlFor="industry"
                    label="What industry are you in?"
                    error={errors.industry}
                  />
                  <StyledSelect
                    aria-labelledby="industry-label"
                    labelId="industry"
                    id="industry"
                    name="industry"
                    disabled={org !== undefined && org !== null}
                    value={formData.industry}
                    error={!!errors.industry}
                    label="What industry are you in?"
                    fullWidth
                    onChange={handleChange}
                    inputProps={{ "aria-labelledby": "industry" }}
                  >
                    {industriesOptions.map((option, i) => (
                      <MenuItem value={option} key={i}>
                        {option}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                  {errors.industry && (
                    <Label
                      htmlFor="industry"
                      error={errors.industry}
                      label={errors.industry.toString()}
                    />
                  )}
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  borderRadius: "25px",
                  fontSize: "16px",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                Continue
              </Button>
            </Box>
          </Box>
        </>
      }
    />
  );
}
