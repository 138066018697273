import { Suspense } from "react";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { RouteList } from "../../route-list";
import { theme } from "../../assets/theme";
import { UserContextProvider } from "../../contexts/user-context";
import { ApiClientProvider } from "../../contexts/ApiClientProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { ErrorProvider } from "../../contexts/ErrorContext";
import { StandardsProvider } from "../../contexts/StandardsProvider";

export default function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<CircularProgress size="20px" />}>
          <UserContextProvider>
            <ErrorProvider>
              <ApiClientProvider>
                <StandardsProvider>
                  <RouteList />
                </StandardsProvider>
              </ApiClientProvider>
            </ErrorProvider>
          </UserContextProvider>
        </Suspense>
      </ThemeProvider>
    </div>
  );
}
