export class AppException extends Error {
  code: string;
  constructor(message: string, code = "", options = {}) {
    super(message);
    this.code = code;
    Object.setPrototypeOf(this, AppException.prototype);
  }
}

export class APIException extends AppException {
  constructor(message: string, code = "", options = {}) {
    super(message, code);
    Object.setPrototypeOf(this, APIException.prototype);
  }
}
