import { FC, ReactNode } from "react";
import { CssBaseline, Grid } from "@mui/material";
import Paper from "@mui/material/Paper";

export type TwoColumnLayoutProps = {
  leftChild?: ReactNode;
  rightChild?: ReactNode;
};

export const TwoColumnLayout: FC<TwoColumnLayoutProps> = ({
  leftChild,
  rightChild,
}) => (
  <Grid container component="main" sx={{ height: "100vh" }}>
    <CssBaseline />
    <Grid
      item
      xs={false}
      sm={false}
      md={4}
      lg={3}
      xl={2.5}
      sx={{
        backgroundColor: "#FBBF24",
      }}
    >
      {leftChild}
    </Grid>
    <Grid item xs={12} sm={12} md={8} lg={9} xl={9.5} component={Paper} square>
      {rightChild}
    </Grid>
  </Grid>
);
