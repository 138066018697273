import React from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Container,
  IconButton,
} from "@mui/material";
import logo from "../../assets/images/logo-app.jpg";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router";
import { Paths } from "../../types/app";

type QuestionnaireHeaderProps = {
  standardName: string;
};

export const QuestionnaireHeader: React.FC<QuestionnaireHeaderProps> = ({
  standardName,
}) => {
  const navigate = useNavigate();

  const closeQuestionnaire = () => {
    navigate(Paths.ACTION_PLAN_HOME_PAGE);
  };

  return (
    <AppBar
      position="static"
      sx={{
        padding: "10px",
        backgroundColor: "#fff",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar>
          <Box component="img" alt="TEDI" src={logo} />
          <Box sx={{ flexGrow: 1, textAlign: "center", color: "#9CA3AF" }}>
            <>
              Standards /{" "}
              <Typography
                display="inline"
                fontWeight={600}
                sx={{
                  color: "#1F2937",
                }}
              >
                {standardName}
              </Typography>
            </>
          </Box>
          <IconButton
            size="medium"
            onClick={() => closeQuestionnaire()}
            sx={{
              color: "#9CA3AF",
            }}
          >
            <ClearIcon />
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
