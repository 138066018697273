type EnvType = "local" | "dev" | "prod";
type AppConfig = {
  [key in EnvType]?: {
    apiUrl: string;
    cognito: {
      region: string;
      userPoolId: string;
      clientId: string;
    };
  };
};

const configs: AppConfig = {
  local: {
    apiUrl: "http://localhost:8000",
    cognito: {
      region: "ap-southeast-2",
      userPoolId: "ap-southeast-2_np3dYvKwW",
      clientId: "jfg9fuu50hu4el1nbnpfcj5c",
    },
  },
  dev: {
    apiUrl: "https://api.dev.tedistandards.com",
    cognito: {
      region: "ap-southeast-2",
      userPoolId: "ap-southeast-2_np3dYvKwW",
      clientId: "jfg9fuu50hu4el1nbnpfcj5c",
    },
  },
  prod: {
    apiUrl: "https://api.prod.tedistandards.com",
    cognito: {
      region: "ap-southeast-2",
      userPoolId: "ap-southeast-2_0Lrpdw5An",
      clientId: "6ca4d4kngol5aglk7u5niqg0c6",
    },
  },
};

export const config =
  configs[(process.env.REACT_APP_ENV as EnvType) || "local"];
