import { Grid, Typography } from "@mui/material";
import { QuickLinkCard } from "../QuickLinkCard";

export const QuickLinks = (): React.ReactElement => {
  return (
    <div>
      <Typography variant="h2" className="ap-home-page-heading margnT40">
        Quick Links
      </Typography>
      <Grid container>
        <QuickLinkCard
          title="Full list of standards"
          buttonText="Go"
          buttonLink="https://tedistandards.com/thestandards"
        />
        <QuickLinkCard
          title="FAQ"
          buttonText="Go"
          buttonLink="http://tedistandards.com/faq-platform"
        />
        <QuickLinkCard
          title="T-EDI Standards website"
          buttonText="Go"
          buttonLink="https://tedistandards.com"
        />
      </Grid>
    </div>
  );
};
