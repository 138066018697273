import { jwtDecode, JwtPayload } from "jwt-decode";

export const getUserFromToken = (idToken: string) => {
  return jwtDecode<JwtPayload>(idToken);
};

export const timestampExpired = (exp: number | undefined) => {
  if (exp && exp > Date.now() / 1000) {
    return false;
  } else {
    return true;
  }
};

export const hasTokenExpired = (accessToken: string) => {
  const { exp } = getUserFromToken(accessToken);
  return timestampExpired(exp);
};

export function hasProps<T, U extends string | number>(
  obj: T,
  ...propName: U[]
): obj is T & { [P in U]: unknown } {
  return (
    !!obj &&
    (typeof obj == "object" || typeof obj == "function") &&
    propName.every((x) => x in obj)
  );
}
