import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { IStandards } from "../types/standard";
import { IUser } from "../types/user";

export interface IUserContext {
  accessToken?: string;
  idToken?: string;
  refreshToken?: string;
  user?: IUser;
  selectedStandard?: IStandards;
  set: (v: Partial<Omit<IUserContext, "set" | "clear">>) => void;
  clear: () => void;
}

export const UserContext = createContext<IUserContext>({
  set: () => 0,
  clear: () => 0,
});

export const useUserContext = (): IUserContext => {
  return useContext(UserContext);
};

export function UserContextProvider({ children }: { children: ReactElement }) {
  const storageKey = "tedi-user-context";
  const cachedConfig = JSON.parse(
    localStorage.getItem(storageKey) || "{}",
  ) as IUserContext;

  const [value, setValue] = useState<IUserContext>();

  const clearContext = () => {
    setValue((_value) => ({
      ...{},
      set: _setV,
      clear: () => clearContext(),
    }));
    localStorage.clear();
  };

  const _setV = (v: Partial<Omit<IUserContext, "set" | "clear">>) => {
    setValue((value) => ({
      ...value,
      ...v,
      set: _setV,
      clear: () => clearContext(),
    }));
  };

  const initial: IUserContext = value
    ? value
    : Object.freeze({
        accessToken: cachedConfig.accessToken,
        idToken: cachedConfig.idToken,
        refreshToken: cachedConfig.refreshToken,
        user: { ...cachedConfig.user! },
        set: _setV,
        clear: () => clearContext(),
      });

  useEffect(() => setValue(initial), [initial]);
  useEffect(() => {
    if (value) {
      localStorage.setItem(storageKey, JSON.stringify(value));
    }
  }, [value]);

  return (
    <UserContext.Provider value={initial}>{children}</UserContext.Provider>
  );
}
