import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import {
  Bar,
  BarChart,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { IconPathLookupType, InsightByStandard } from "../../types/insights";
import { mapIcons } from "../StandardsCard";
import { InfoTooltip } from "../InfoTooltip";

type StandardsGraphProps = {
  standardInsights: InsightByStandard[];
  average: number;
  iconPathLookup: IconPathLookupType | undefined;
};

type CustomizedLabelType = {
  viewBox: {
    x: number | string;
    y: number | string;
  };
};

const levelLabels: { [key: number]: string } = {
  30: "Foundation",
  80: "Advanced",
  95: "Leading",
};

const tickFormatter = (tick: number) => levelLabels[tick];

const overallLevel = (score: number) => {
  const levelScore = Object.keys(levelLabels).map(Number);
  if (score < levelScore[0]) {
    return "Building";
  } else if (score < levelScore[1]) {
    return levelLabels[levelScore[0]];
  } else if (score < levelScore[2]) {
    return levelLabels[levelScore[1]];
  } else {
    return levelLabels[levelScore[2]];
  }
};

export const StandardsGraph = ({
  standardInsights,
  average,
  iconPathLookup,
}: StandardsGraphProps): React.ReactElement => {
  const CustomizedLabel = (props: CustomizedLabelType) => {
    return (
      <text
        x={props.viewBox.x}
        y={props.viewBox.y}
        dy={-4}
        fill={"#000"}
        fontSize={14}
        fontWeight={500}
        textAnchor="start"
      >
        Avg: {overallLevel(average)}
      </text>
    );
  };
  return (
    <Grid container>
      <Card className="insight-card width100">
        <CardContent className="benchmarking-container">
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            className="insight-card-heading"
          >
            Standards Progress
            <InfoTooltip title="At-a-glance progress against each standard category" />
          </Typography>
          <ResponsiveContainer width="99%" height={500}>
            <BarChart
              data={standardInsights}
              margin={{
                top: 35,
                right: 30,
                left: 30,
                bottom: 75,
              }}
            >
              <XAxis
                dataKey="name"
                axisLine={{ stroke: "#E9E9E9" }}
                angle={-35}
                tick={{ textAnchor: "end", fontSize: "13px", fill: "#000" }}
              />
              <YAxis
                dataKey="maturity"
                ticks={[30, 80, 95]}
                tickFormatter={tickFormatter}
                tick={{ textAnchor: "end", fontSize: "13px", fill: "#888888" }}
                axisLine={{ stroke: "#E9E9E9" }}
                interval={0}
              />
              <Bar
                dataKey="score"
                fill="#8884d8"
                barSize={30}
                radius={[4, 4, 0, 0]}
              >
                {standardInsights.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Bar>
              <ReferenceLine
                y={average}
                label={CustomizedLabel}
                strokeDasharray="3 3"
                stroke="#faaa0a"
                isFront={true}
              />
            </BarChart>
          </ResponsiveContainer>
          <Grid
            container
            sx={{
              marginTop: "15px",
            }}
          >
            <Grid item xs={12} sm={12} md={9} lg={12} xl={12}>
              <Grid sx={{ display: "flex", flexWrap: "wrap", margin: "15px" }}>
                {standardInsights.length > 0 &&
                  standardInsights.map((eachStandard, key) => {
                    return (
                      <Box
                        key={key}
                        className="insight-card-container"
                        sx={{
                          minWidth: "260px",
                          border: "0.2px solid #f3f4f6",
                        }}
                      >
                        <Box className="insight-card-secondary-heading">
                          <Box
                            component="img"
                            alt={eachStandard.name + " icon"}
                            src={
                              iconPathLookup
                                ? mapIcons[iconPathLookup[eachStandard.id]]
                                : ""
                            }
                            sx={{
                              width: "16px",
                            }}
                          />
                          <Typography
                            gutterBottom
                            variant="body1"
                            sx={{
                              display: "inline-block",
                              marginLeft: "10px",
                              marginBottom: "0px",
                              fontSize: "15px",
                            }}
                          >
                            {eachStandard.name.replace(/Mgmt./g, "Management")}
                          </Typography>
                        </Box>

                        <Typography variant="h4" className="insight-card-value">
                          {eachStandard.stats.done +
                            "/" +
                            eachStandard.stats.total}
                        </Typography>
                      </Box>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
