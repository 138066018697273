import { Box } from "@mui/material";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { ScoreType } from "../../types/action-item";

type CircularProgressType = {
  foundationScore: ScoreType | undefined;
  advancedScore: ScoreType | undefined;
  leadingScore: ScoreType | undefined;
};

export const CircularProgress = ({
  foundationScore,
  advancedScore,
  leadingScore,
}: CircularProgressType) => {
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="250px"
      height="250px"
      margin="0 auto"
    >
      {/* Outer Gauge */}
      <Box position="absolute" width="200px" height="200px">
        <CircularProgressbar
          value={
            leadingScore
              ? (leadingScore.completed / leadingScore.total) * 100
              : 0
          }
          strokeWidth={6}
          styles={buildStyles({
            strokeLinecap: "butt",
            trailColor: "#f0f0f0",
            pathColor: "#10b981",
          })}
        />
      </Box>
      {/* Middle Gauge */}
      <Box position="absolute" width="150px" height="150px">
        <CircularProgressbar
          value={
            advancedScore
              ? (advancedScore.completed / advancedScore.total) * 100
              : 0
          }
          strokeWidth={8}
          styles={buildStyles({
            strokeLinecap: "butt",
            trailColor: "#f0f0f0",
            pathColor: "#40BEF3",
          })}
        />
      </Box>
      {/* Inner Gauge */}
      <Box position="absolute" width="100px" height="100px">
        <CircularProgressbar
          value={
            foundationScore
              ? (foundationScore.completed / foundationScore.total) * 100
              : 0
          }
          strokeWidth={10}
          styles={buildStyles({
            strokeLinecap: "butt",
            trailColor: "#f0f0f0",
            pathColor: "#FCD34D",
          })}
        />
      </Box>
    </Box>
  );
};
