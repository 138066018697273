import { Route, Routes } from "react-router";
import Login from "./scenes/Login";
import RegisterUser from "./scenes/RegisterUser";
import ConfirmEmail from "./scenes/ConfirmEmail";
import OrganisationDetails from "./scenes/OrganisationDetails";
import { Paths } from "./types/app";
import { LoginProtect } from "./components/LoginProtect";
import Questionnaire from "./scenes/Questionnaire";
import ActionPlanHomePage from "./scenes/ActionPlanHomePage";
import ActionPlan from "./scenes/ActionPlan";
import Layout from "./components/Layout";
import Insights from "./scenes/Insights";
import Verification from "./scenes/Verification";
import { MyToken } from "./scenes/Token";

export const RouteList = () => {
  return (
    <Routes>
      <Route path={Paths.LOGIN} element={<Login />}></Route>
      <Route path={Paths.REGISTER} element={<RegisterUser />} />
      <Route path={Paths.CONFIRM_EMAIL} element={<ConfirmEmail />} />

      <Route element={<LoginProtect />}>
        <Route path={Paths.ORGANISATION} element={<OrganisationDetails />} />
        <Route path={Paths.QUESTIONNAIRE} element={<Questionnaire />} />
        <Route
          path={Paths.VERIFICATION + `approve/:id`}
          element={<Verification />}
        />
        <Route
          path={Paths.VERIFICATION + `reject/:id`}
          element={<Verification />}
        />
        <Route path={Paths.DASHBOARD} element={<Layout />}>
          <Route index element={<Insights />} />
        </Route>
        <Route path={Paths.ACTION_PLAN_HOME_PAGE} element={<Layout />}>
          <Route index element={<ActionPlanHomePage />} />
          <Route
            path={Paths.ACTION_PLAN_HOME_PAGE + `/:id`}
            element={<ActionPlan />}
          />
        </Route>
        <Route path={Paths.SEE_TOKEN} element={<MyToken />} />
      </Route>
    </Routes>
  );
};
