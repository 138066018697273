import { Box, Typography } from "@mui/material";
import logo from "../../assets/images/logo.png";

export const SidePanel = (): React.ReactElement => {
  return (
    <Box
      sx={{
        my: 5,
        mx: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        component="img"
        sx={{
          height: 233,
          width: 350,
          maxHeight: { xs: 108, sm: 108, md: 90, lg: 102, xl: 102 },
          maxWidth: { xs: 311, sm: 311, md: 267, lg: 251, xl: 312 },
        }}
        alt="TEDI"
        src={logo}
      />
      <Typography
        component="h1"
        variant="h5"
        sx={{ fontWeight: 500, textAlign: "left", mt: 6, fontSize: "29px" }}
      >
        Standards for a better workplace
      </Typography>
      <Typography component="p" sx={{ textAlign: "left" }}>
        Improve gender diversity maturity simply with the TEDI Standards. Track
        progress, set goals and share data with collaborators and stakeholders.
        Let’s work together to make a meaningful impact!
      </Typography>
    </Box>
  );
};
