import { createContext, ReactElement, useCallback, useMemo } from "react";
import { config } from "../configs/app-config";
import { useUserContext } from "./user-context";
import { CommonErrorCodes } from "../errors/common-error-codes";
import { AppException, APIException } from "../exceptions/exceptions";
import { ApiClient } from "../services/api-client";
import { IAuthTokens } from "../types/user";

export let ApiClientContext = createContext<ApiClient | undefined>(undefined);

export function ApiClientProvider({ children }: { children: ReactElement }) {
  const userContext = useUserContext();

  const createClient = useCallback((): ApiClient => {
    try {
      return ApiClient.getInstance();
    } catch (e) {
      if (e instanceof AppException) {
        if (!userContext.accessToken) {
          throw new APIException(
            "not authentication info in context",
            CommonErrorCodes.AUTH_ERROR,
          );
        }
        return ApiClient.createInstance({
          baseUrl: config!.apiUrl,
          accessToken: userContext.accessToken!,
          idToken: userContext.idToken!,
          refreshToken: userContext.refreshToken!,
          tokenRefreshCallback: (newTokens: IAuthTokens) => {
            userContext.set(newTokens);
          },
        });
      } else {
        throw e;
      }
    }
  }, [userContext]);

  const apiClient = useMemo(() => {
    try {
      return createClient();
    } catch (e) {
      console.log("Couldn't init api client");
      return;
    }
  }, [createClient]);

  return (
    <ApiClientContext.Provider value={apiClient}>
      {children}
    </ApiClientContext.Provider>
  );
}
