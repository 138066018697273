import { Box, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import {
  Bar,
  Cell,
  ComposedChart,
  ReferenceDot,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { InfoTooltip } from "../InfoTooltip";
import { useStandards } from "../../contexts/StandardsProvider";
import { IStandards } from "../../types/standard";
import { useState } from "react";
import InfoDialog from "../InfoDialog";
import maturityList from "./maturity.json";

type CustomToolTipType = {
  active: boolean;
  payload: [
    {
      payload: {
        stage: string;
        score: number;
      };
    },
  ];
};

const stages = [
  { name: "Compliant", min: 0, max: 20 },
  { name: "Discovering", min: 21, max: 50 },
  { name: "Committing", min: 51, max: 85 },
  { name: "Championed", min: 86, max: 96 },
  { name: "Leading", min: 97, max: 100 },
];

const levels = [
  { name: "Building", min: 0, max: 30 },
  { name: "Foundation", min: 31, max: 80 },
  { name: "Advanced", min: 81, max: 94 },
  { name: "Leading", min: 95, max: 100 },
];

const getGraphData = (score: number) => {
  return stages.map((stage) => ({
    stage: stage.name,
    min: stage.min,
    max: stage.max,
    range: stage.max - 0,
    score: score >= stage.min && score <= stage.max ? score : null,
  }));
};

const levelTickFormatter = (tick: number) => {
  const level = levels.find((l) => tick >= l.min && tick <= l.max);
  return level ? level.name : "";
};

const CustomTooltip = ({ active, payload }: CustomToolTipType) => {
  if (active && payload && payload.length) {
    const { stage, score } = payload[0].payload;
    const x = stage;
    const y = score;

    const stageObj = stages.find((s) => s.name === x);
    const level = levels.find((l) => y >= l.min && y <= l.max);
    if (score) {
      return (
        <div className="custom-tooltip">
          <p className="score label">{`${y}%`}</p>
          <p className="label">{`Level: ${level ? level.name : ""}`}</p>
          <p className="label">{`Maturity: ${stageObj ? stageObj.name : ""}`}</p>
        </div>
      );
    } else {
      return null;
    }
  }
  return null;
};

const customBarColor = (stage: string) => {
  switch (stage) {
    case "Compliant":
      return "#fffaef";
    case "Discovering":
      return "#fef4e0";
    case "Committing":
      return "#feefd2";
    case "Championed":
      return "#feefd2";
    case "Leading":
      return "#fde5b5";
    default:
      return "#fde5b5";
  }
};

const getStandardScore = (standards: IStandards[]) => {
  return standards
    .map((standard) => (standard.computedScore ? standard.computedScore : 0))
    .reduce((acc, currentValue) => acc + currentValue, 0);
};

export const MaturityGraph = (): React.ReactElement => {
  const [openDialog, setOpenDialog] = useState(false);
  const { submittedStandards, unsubmittedStandards } = useStandards();

  const submittedScore = getStandardScore(submittedStandards);
  const unsubmittedScore = getStandardScore(unsubmittedStandards);
  const averageScore =
    (submittedScore + unsubmittedScore) /
    (submittedStandards.length + unsubmittedStandards.length);
  const score = parseInt((averageScore * 100).toFixed(2));
  const data = getGraphData(score);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Grid container sx={{ paddingBottom: 2 }}>
      <Card className="insight-card width100">
        <CardContent className="benchmarking-container">
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            className="insight-card-heading"
            sx={{
              marginBottom: 4,
            }}
          >
            Maturity Level
            <InfoTooltip title="At-a-glance progress shown here on the gender DEI maturity scale, based on your completed self-assessments." />
          </Typography>
          <Grid
            container
            sx={{
              margin: 3,
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={2}
              xl={1.5}
              className="left"
              sx={{
                padding: "24px 0px",
              }}
            >
              <Box
                sx={{
                  marginTop: 1,
                  marginBottom: 6,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 500,
                    color: "#4B5563",
                  }}
                >
                  Current Level
                </Typography>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 600,
                    color: "#232B33",
                  }}
                >
                  {data.map((eachStage) =>
                    eachStage.score ? levelTickFormatter(eachStage.score) : "",
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginBottom: 2,
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 500,
                    color: "#4B5563",
                  }}
                >
                  Current Maturity
                </Typography>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 600,
                    color: "#232B33",
                  }}
                >
                  {data.map((eachStage) =>
                    eachStage.score ? eachStage.stage : "",
                  )}
                </Typography>
                <Link
                  component="button"
                  variant="body2"
                  sx={{
                    color: "#017FB4",
                    textDecoration: "none",
                  }}
                  onClick={() => {
                    handleClickOpen();
                  }}
                >
                  Read More
                </Link>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={8.5}
              lg={9.5}
              xl={10}
              sx={{
                border: "1px solid #EFEFEF",
                borderRadius: "12px",
              }}
            >
              <ResponsiveContainer width="99%" height={400}>
                <ComposedChart
                  data={data}
                  margin={{
                    top: 35,
                    right: 30,
                    left: 30,
                    bottom: 75,
                  }}
                >
                  <XAxis
                    dataKey="stage"
                    axisLine={{ stroke: "#E9E9E9" }}
                    tick={{ fontSize: "13px", fill: "#000", fontWeight: "600" }}
                  />
                  <YAxis
                    domain={[0, 100]}
                    tickFormatter={levelTickFormatter}
                    type="number"
                    ticks={[30, 80, 94, 100]}
                    interval={0}
                    axisLine={{ stroke: "#E9E9E9" }}
                    tick={{ fontSize: "13px", fill: "#374151" }}
                  />
                  <Bar dataKey="range" fill="#fde5b5" radius={[4, 4, 0, 0]}>
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={customBarColor(entry.stage)}
                      />
                    ))}
                  </Bar>
                  {data.map((entry, index) => (
                    <ReferenceDot
                      key={index}
                      x={entry.stage}
                      y={entry.score!}
                      r={15}
                      fill="#faaa0a"
                      stroke="#DD7600"
                      ifOverflow="visible"
                      isFront={true}
                    />
                  ))}

                  <Tooltip
                    /* @ts-expect-error */
                    content={<CustomTooltip />}
                    isAnimationActive={false}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <InfoDialog
        open={openDialog}
        handleClose={handleClose}
        title="Maturity Levels"
        dialogInformation={maturityList.map((eachMaturity, i) => {
          return (
            <Box key={i} sx={{ marginBottom: 2, marginLeft: 1 }}>
              <Typography sx={{ fontWeight: 600 }}>
                {eachMaturity.title}:
              </Typography>
              <Typography gutterBottom>{eachMaturity.description}</Typography>
            </Box>
          );
        })}
      />
    </Grid>
  );
};
