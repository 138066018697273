import { useEffect, useState } from "react";
import { useUserContext } from "../../contexts/user-context";
import { useLocation } from "react-router";
import { getUserFromToken } from "../../utils/util";
import { JwtPayload } from "jwt-decode";
import { verifyRequestAdmin } from "../../services/standards-service";
import { AppError } from "../../types/app";
import { useError } from "../../contexts/ErrorContext";
import { Box, Typography } from "@mui/material";
import { IVerifyRequest } from "../../types/action-item";

export default function Verification() {
  const { accessToken } = useUserContext();
  const location = useLocation();
  const [verifiedResponse, setVerifiedResponse] = useState<
    IVerifyRequest | undefined
  >(undefined);
  const { handleError } = useError();

  const verifyRequest = async () => {
    try {
      const data = await verifyRequestAdmin(location.pathname);
      setVerifiedResponse(data);
    } catch (e) {
      const error = e as AppError;
      if (error && error.message) {
        handleError(error.message);
      }
    }
  };
  useEffect(() => {
    if (accessToken && location.pathname) {
      const decodeAccessToken: JwtPayload & { "cognito:groups"?: string[] } =
        getUserFromToken(accessToken);
      if (
        decodeAccessToken["cognito:groups"] &&
        decodeAccessToken["cognito:groups"][0] === "ADMIN"
      ) {
        verifyRequest();
      }
    }
    // eslint-disable-next-line
  }, []);

  return verifiedResponse ? (
    <Box sx={{ margin: 4 }}>
      <Typography>Status: {verifiedResponse.status}</Typography>
      <Typography>Items updated: {verifiedResponse.itemsUpdated}</Typography>
      <Typography sx={{ marginTop: 4, fontWeight: 600 }}>
        Thank you! Please close the window
      </Typography>
    </Box>
  ) : (
    <></>
  );
}
