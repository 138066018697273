import { Box, Card, CardActions, CardContent, Typography } from "@mui/material";
import { ReactNode } from "react";
import {
  hiringIcon,
  performanceManagementIcon,
  cultureValuesIcon,
  leadershipIcon,
  sltExecIcon,
  flexibleWorkIcon,
  parentalLeaveIcon,
  transparentPayIcon,
  policiesIcon,
  respectWorkIcon,
} from "../../assets/icons";
import { IStandards } from "../../types/standard";

type StandardsCardProps = {
  standard: IStandards;
  ButtonComponent: ReactNode;
};

export const mapIcons = {
  hiring: hiringIcon,
  performanceManagement: performanceManagementIcon,
  cultureValues: cultureValuesIcon,
  leadership: leadershipIcon,
  sltExec: sltExecIcon,
  flexibleWork: flexibleWorkIcon,
  parentalLeave: parentalLeaveIcon,
  transparentPay: transparentPayIcon,
  policies: policiesIcon,
  respectWork: respectWorkIcon,
};

export const StandardsCard = ({
  standard,
  ButtonComponent,
}: StandardsCardProps): React.ReactElement => {
  return (
    <Card className="standardCard">
      <CardContent sx={{ padding: 0 }}>
        <Box
          component="img"
          alt={standard.title + " icon"}
          src={mapIcons[standard.iconPath]}
        />
        <Typography variant="h2" className="ap-home-page-heading">
          {standard.title}
        </Typography>
      </CardContent>
      <CardActions sx={{ padding: 0 }}>{ButtonComponent}</CardActions>
    </Card>
  );
};
