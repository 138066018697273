import { InputBase, InputBaseProps } from "@mui/material";
import { styled } from "@mui/material/styles";

interface BootstrapInputProps extends InputBaseProps {
  error?: boolean;
}

export const BootstrapInput = styled(InputBase)<BootstrapInputProps>(
  ({ theme, error }) => ({
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#FFFFFF" : "#1A2027",
      border: "1px solid",
      borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      "&:hover": {
        borderColor: "#374151",
      },
      "&:focus": {
        borderColor: "#FAAA0A",
        ...(error && {
          borderColor: "#E3022C",
        }),
      },
      ...(error && {
        backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
        borderColor: theme.palette.mode === "light" ? "#E3022C" : "#FF4E6F",
      }),
    },
  }),
);
