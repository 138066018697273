import React, { useState, useEffect } from "react";
import { Button, Typography } from "@mui/material";

type TimerProps = {
  handleResendCode: () => void;
  timeout?: number;
};

export const Timer: React.FC<TimerProps> = ({
  handleResendCode,
  timeout = 60,
}) => {
  const [timeLeft, setTimeLeft] = useState<number>(timeout); // 1 minute

  useEffect(() => {
    if (timeLeft === 0) return;
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    if (minutes > 0) {
      return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
    } else {
      return `${remainingSeconds.toString().padStart(2, "0")} seconds`;
    }
  };

  const handleResetTimer = () => {
    setTimeLeft(60);
    handleResendCode();
  };

  return (
    <>
      {timeLeft !== 0 ? (
        <>
          {`in `}
          <Typography variant="body2" fontWeight="700" display="inline">
            {formatTime(timeLeft)}{" "}
          </Typography>
        </>
      ) : (
        <Button
          variant="text"
          color="primary"
          onClick={() => handleResetTimer()}
        >
          Resend Code
        </Button>
      )}
    </>
  );
};
