import { Box, Grid } from "@mui/material";
import { QuestionnaireHeader } from "../../components/QuestionnaireHeader";
import { TypeForm } from "../../components/TypeForm";
import { submitForm } from "../../services/standards-service";
import { AppError, Paths } from "../../types/app";
import { useLocation, useNavigate } from "react-router";
import { IStandards } from "../../types/standard";
import { useError } from "../../contexts/ErrorContext";
import { useCallback, useEffect } from "react";

export default function Questionnaire() {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleError } = useError();
  const { standard } =
    (location.state as {
      standard: IStandards;
    }) || {};

  let redirectIntervalId: NodeJS.Timeout;

  const redirectUser = useCallback(() => {
    // eslint-disable-next-line
    redirectIntervalId = setTimeout(() => {
      navigate(Paths.ACTION_PLAN_HOME_PAGE);
    }, 10000);
  }, []);

  const submitStandard = async (formId: string, responseId: string) => {
    try {
      await submitForm({
        standardId: standard.id,
        externalResponseId: responseId,
      });
      redirectUser();
    } catch (e) {
      const error = e as AppError;
      if (error && error.message) {
        handleError(error.message);
      }
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(redirectIntervalId);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      sx={{
        background: "#F7F7F7",
      }}
    >
      <QuestionnaireHeader standardName={standard.title} />
      <Grid container>
        <Grid
          item
          xs={9}
          sx={{
            margin: "0 auto",
          }}
        >
          <TypeForm
            formId={standard.externalFormId}
            submitStandard={submitStandard}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
