import Axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosRequestHeaders,
} from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { CommonErrorCodes } from "../errors/common-error-codes";
import { APIException, AppException } from "../exceptions/exceptions";
import { IAuthTokens } from "../types/user";
import { refreshAccessToken } from "./cognito";

export interface ApiClientConfiguration {
  baseUrl: string;
  accessToken: string;
  idToken: string;
  refreshToken: string;
  tokenRefreshCallback: (data: IAuthTokens) => void;
}
export class ApiClient {
  private baseUrl: string;
  private axiosClient: AxiosInstance;
  private accessToken: string = "";
  private idToken: string = "";
  private refreshToken: string = "";
  private tokenRefreshCallback: (data: IAuthTokens) => void;

  private static instance: ApiClient;

  // private static getInstanceKey(config: Configuration) {
  //   return `key::${config.baseUrl}`;
  // }

  public static getInstance(): ApiClient {
    if (ApiClient.instance) return ApiClient.instance;
    else
      throw new AppException(
        "api client not configured",
        CommonErrorCodes.INCORRECT_STATE,
      );
  }

  public static createInstance(config: ApiClientConfiguration): ApiClient {
    ApiClient.instance = new ApiClient(config);
    return ApiClient.instance;
  }

  private constructor(private config: ApiClientConfiguration) {
    this.baseUrl = config.baseUrl;
    this.accessToken = config.accessToken;
    this.idToken = config.idToken;
    this.refreshToken = config.refreshToken;
    this.tokenRefreshCallback = config.tokenRefreshCallback;

    this.axiosClient = Axios.create({
      timeout: 60000,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.axiosClient.interceptors.request.use(
      (request) => {
        request.headers["Authorization"] = `Bearer ${this.accessToken}`;
        return request;
      },
      (error) => {
        console.error(error);
        return error;
      },
      { synchronous: true },
    );

    const refreshTokenInterceptor = (failedRequest: AxiosRequestHeaders) => {
      return this.refreshTokenCall().then((newToken: string) => {
        failedRequest.response.config.headers["Authorization"] =
          `Bearer ${newToken}`;
      });
    };

    createAuthRefreshInterceptor(this.axiosClient, refreshTokenInterceptor, {
      statusCodes: [401, 403],
      pauseInstanceWhileRefreshing: true,
    });
  }

  public getConfig() {
    return { ...this.config };
  }

  private async generateToken(): Promise<string> {
    if (this.accessToken) {
      return Promise.resolve(this.accessToken);
    }
    throw new Error("Not implemented");
  }

  private async refreshTokenCall(): Promise<string> {
    const response = await refreshAccessToken(this.refreshToken);
    if (
      response.AuthenticationResult &&
      response.AuthenticationResult.AccessToken
    ) {
      const authResult = response.AuthenticationResult;
      this.tokenRefreshCallback({
        accessToken: authResult.AccessToken!,
        idToken: authResult.IdToken,
        expires: authResult.ExpiresIn,
      });

      this.accessToken = authResult.AccessToken!;
      this.refreshToken = authResult.RefreshToken || "";
      this.idToken = authResult.IdToken || "";

      return this.accessToken;
    }

    throw new AppException(
      "Couldn't refresh access token",
      CommonErrorCodes.COGNITO_AUTH_ERROR,
    );
  }

  async get<R>(
    endpoint: string,
    queryParams: { [key: string]: string } = {},
  ): Promise<AxiosResponse<R>> {
    if (this.accessToken === null) {
      await this.generateToken();
    }

    // if it gets more complicated, we can use the qs library
    // https://www.npmjs.com/package/qs
    const queryString = Object.entries(queryParams)
      .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
      .join("&");

    const url = `${this.baseUrl}${endpoint}${
      queryString.length ? "?" + queryString : ""
    }`;

    try {
      const result = await this.axiosClient<R, AxiosResponse<R>>({
        method: "get",
        url,
      });

      return result;
    } catch (error) {
      throw this.transformError(error);
    }
  }

  async post<D, R>(
    endpoint: string,
    body: D = {} as D,
    config = {},
  ): Promise<AxiosResponse<R>> {
    if (this.accessToken === null) {
      await this.generateToken();
    }

    try {
      const result = await this.axiosClient<R, AxiosResponse<R>, D>({
        method: "post",
        url: `${this.baseUrl}${endpoint}`,
        data: body,
        ...config,
      });

      return result;
    } catch (error) {
      throw this.transformError(error);
    }
  }

  async put<D, R>(
    endpoint: string,
    body: D = {} as D,
  ): Promise<AxiosResponse<R>> {
    try {
      const result = await this.axiosClient<R, AxiosResponse<R>, D>({
        method: "put",
        url: `${this.baseUrl}${endpoint}`,
        data: body,
      });

      return result;
    } catch (error) {
      throw this.transformError(error);
    }
  }

  async delete<D, R>(
    endpoint: string,
    body: D = {} as D,
  ): Promise<AxiosResponse<R>> {
    try {
      const result = await this.axiosClient<R, AxiosResponse<R>, D>({
        method: "delete",
        url: `${this.baseUrl}${endpoint}`,
        data: body,
      });

      return result;
    } catch (error) {
      throw this.transformError(error);
    }
  }

  private transformError(error: any) {
    if (Axios.isAxiosError(error)) {
      if (error.response) {
        return new APIException(
          error?.response?.data?.message,
          error?.response?.status.toString(),
        );
      } else if (error.request) {
        console.error(error.request);
        return new APIException(error?.code || "Unknown Error", "500");
      } else {
        console.error("Error", error.message);
        return new APIException(error?.code || "Unknown Error", "500");
      }
    } else {
      return new APIException("Error Occured in API Request", "500");
    }
  }
}
