export type IActionItems = {
  id: number;
  actionText: string;
  actionDescription: string;
  done: boolean;
  link: string;
  standardLevel: 1;
};

export type IVerifiedActionItems = {
  id: number;
  actionPlanItemIds: number[];
  standard: {
    id: number;
    title: string;
  };
  status: string;
  user: {
    id: number;
    emailId: string;
  };
};

export type IVerifyRequest = {
  id: number;
  itemsUpdated: number;
  status: string;
};

export type ActionItemType = {
  actionItemsCompleted: IActionItems[];
  actionItemsToDo: IActionItems[];
};

export type ScoreType = {
  total: number;
  completed: number;
};

export type mapLevelType = {
  [key: number]: string;
};

type ValueOf<T> = T[keyof T];

export const mapLevel: mapLevelType = {
  1: "Foundation",
  2: "Advanced",
  3: "Leading",
};

export type LevelScore = {
  [key in ValueOf<mapLevelType>]?: ScoreType;
};
