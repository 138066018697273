import {
  IconButton,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import styled from "@emotion/styled";

type InfoTooltipType = {
  title: string;
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 11,
  },
}));

export const InfoTooltip = ({ title }: InfoTooltipType) => {
  return (
    <LightTooltip title={title}>
      <IconButton
        sx={{
          padding: "0px 8px",
          display: "inline-block",
          "&:hover": {
            background: "none",
          },
        }}
      >
        <InfoIcon />
      </IconButton>
    </LightTooltip>
  );
};
