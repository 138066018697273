import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xl: 500, lg: 500, md: 500, sm: 300, xs: 300 },
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  borderRadius: 1,
  boxShadow: 24,
};

type ErrorComponentProps = {
  open: boolean;
  handleClose(): void;
  message: string | null;
};

export const ErrorComponent = ({
  open,
  handleClose,
  message,
}: ErrorComponentProps) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            padding: "12px 12px 12px 24px",
          }}
        >
          Error
          <IconButton
            size="medium"
            onClick={handleClose}
            sx={{
              color: "#9CA3AF",
              float: "right",
            }}
          >
            <ClearIcon />
          </IconButton>
        </Typography>
        <Typography
          id="modal-modal-description"
          sx={{
            mt: 2,
            fontSize: "13px",
            color: "#111827",
            padding: "0px 12px 24px 24px",
            borderBottom: 1,
            borderColor: "#E5E7EB",
          }}
        >
          {message}
        </Typography>
        <Button
          onClick={handleClose}
          sx={{
            m: 2,
            float: "right",
            background: "#fff",
            border: "1px solid #E5E7EB",
            borderRadius: 6,
            color: "#374151",
            boxShadow: "none",
            "&:hover": {
              background: "#E5E7EB",
              borderColor: "#6B7280",
              boxShadow: "none",
            },
          }}
          variant="contained"
        >
          OK
        </Button>
      </Box>
    </Modal>
  );
};
