import { theme } from "../../assets/theme";
import { SideMenu } from "../SideMenu";
import { Box, CssBaseline, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router";

const Layout = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{ display: "flex", backgroundColor: "#F9FAFB", overflow: "hidden" }}
    >
      <CssBaseline />
      <SideMenu isMobile={isMobile} />
      <Outlet />
    </Box>
  );
};

export default Layout;
