import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    fontFamily: "aktiv-grotesk",
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
            font-family: "aktiv-grotesk", sans-serif;
            font-weight: 400;
            font-style: normal;
          }
          @font-face {
            font-family: "aktiv-grotesk", sans-serif;
            font-weight: 600;
            font-style: normal;
          }
        `,
    },
  },
  palette: {
    primary: {
      main: "#000",
    },
  },
});
