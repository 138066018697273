import { Box, Card, CardContent, Chip, Grid, Typography } from "@mui/material";
import insightsBenchmarking from "../../assets/images/insights-benchmarking.png";

export const Benchmarking = (): React.ReactElement => {
  return (
    <Grid container sx={{ paddingBottom: 2 }}>
      <Card className="insight-card width100">
        <CardContent className="benchmarking-container">
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            className="insight-card-heading"
          >
            Benchmarking
          </Typography>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="left">
            <Box className="benchmarking-text">
              <Typography gutterBottom variant="body1" component="div">
                Benchmarking will be available soon. You will be able to compare
                your company against other participants in Australia.
              </Typography>
              <Chip label="Coming 2025" className="chip" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="right">
            <Box
              component="img"
              alt="Insights Benchmarking"
              src={insightsBenchmarking}
              className="width100"
            />
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
