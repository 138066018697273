export enum Paths {
  LOGIN = "/",
  CONFIRM_EMAIL = "/confirm",
  REGISTER = "/register",
  ACTION_PLAN_HOME_PAGE = "/action-plans",
  ORGANISATION = "/organisation",
  DASHBOARD = "/dashboard",
  QUESTIONNAIRE = "/questionnaire",
  VERIFICATION = "/verification-requests/",
  SEE_TOKEN = "/my-token",
}

export type AppError = {
  message: string;
  code?: number;
};

export enum LoginError {
  USER_EXISTS = "This email is already linked to a user. Please sign in.",
  USER_NOT_FOUND = "Email address is not registered to any account",
  INCORRECT_CODE = "The code you entered is incorrect",
  SESSION_TIMEOUT = "Your session has timed out, you'll be redirected to the login page",
  WORK_EMAIL = "Please use work email.",
  TOKEN_EXPIRED = "The invitation request for this email id has expired.",
}
