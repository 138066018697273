import { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SidePanel } from "../../components/SideInfoPanel";
import { TwoColumnLayout } from "../../components/TwoColumnLayout";
import { Navigate, useLocation, useNavigate } from "react-router";
import { AppError, LoginError, Paths } from "../../types/app";
import { IAuthTokens, IUserFormData } from "../../types/user";
import { Timer } from "../../components/Timer";
import { sendCode, verifyLogin } from "../../services/cognito";
import { InputLabel } from "@mui/material";
import { OtpInput } from "../../components/OtpInput";
import { UserContext } from "../../contexts/user-context";
import { ApiClient } from "../../services/api-client";
import { config } from "../../configs/app-config";
import { getOrCreateUser } from "../../services/user-service";
import { Header } from "../../components/Header";
import { useError } from "../../contexts/ErrorContext";

export default function ConfirmEmail() {
  const { handleError } = useError();
  const [error, setError] = useState<LoginError | undefined>();
  const [otp, setOtp] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const location = useLocation();
  const { emailId, Session, formData, redirectTo, token } =
    (location.state as {
      emailId: string;
      Session: string;
      formData?: IUserFormData;
      redirectTo?: string;
      token?: string;
    }) || {};
  const [userSession, setUserSession] = useState(Session);
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  const handleChange = (newValue: string) => {
    setOtp(newValue);
    if (newValue.length === 6) {
      setIsButtonDisabled(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(undefined);
    try {
      const response = await verifyLogin(otp, emailId, userSession);
      const authResult = response.AuthenticationResult;
      if (!authResult && response.Session) {
        setError(LoginError.INCORRECT_CODE);
        setUserSession(response.Session);
      } else if (authResult && authResult.AccessToken) {
        ApiClient.createInstance({
          baseUrl: config?.apiUrl!,
          accessToken: authResult.AccessToken,
          refreshToken: authResult.RefreshToken!, // check if always defined!
          idToken: authResult.IdToken!,
          tokenRefreshCallback: (newTokens: IAuthTokens) => {
            userContext.set(newTokens);
          },
        });
        const user = await getOrCreateUser(authResult, formData, token);
        userContext.set({
          accessToken: authResult.AccessToken,
          idToken: authResult.IdToken,
          refreshToken: authResult.RefreshToken,
          user,
        });
        if (user.org === null || user.org === undefined) {
          navigate(Paths.ORGANISATION);
        } else {
          if (redirectTo) {
            navigate(redirectTo);
          } else {
            navigate(Paths.ACTION_PLAN_HOME_PAGE);
          }
        }
      }
    } catch (e) {
      const error = e as AppError;
      if (error && error.message) {
        handleError(error.message);
      }
      setTimeout(() => navigate(Paths.LOGIN), 3000); // todo: show a spinner
    }
  };

  const handleResendCode = async () => {
    setError(undefined);
    try {
      const { Session } = await sendCode(emailId);
      if (Session) {
        setUserSession(Session);
      }
    } catch (e) {
      setError(LoginError.SESSION_TIMEOUT);
      setTimeout(() => navigate(Paths.LOGIN), 3000); // todo: show a spinner
    }
  };

  if (!Session) {
    return <Navigate to={Paths.LOGIN} />;
  }

  return (
    <TwoColumnLayout
      leftChild={<SidePanel />}
      rightChild={
        <>
          <Header />
          <Box
            sx={{
              my: 14,
              mx: { xs: 2, sm: 8, md: 12, lg: 35, xl: 35 },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              component="h1"
              variant="h4"
              sx={{ fontWeight: 500, mb: 2 }}
            >
              Check your email for a login code
            </Typography>
            <Typography variant="body1" textAlign="left">
              We’ve sent a 6-digit login code to your email address
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{
                mt: 1,
                width: { xl: 500, lg: 500, md: 500, sm: 370, xs: 345 },
              }}
            >
              <InputLabel htmlFor="otpInput" sx={{ visibility: "hidden" }}>
                OTP Input
              </InputLabel>
              <OtpInput
                TextFieldsProps={{ id: "otpInput" }}
                value={otp}
                onChange={handleChange}
                length={6}
                error={!!error}
              />
              {error && (
                <InputLabel
                  htmlFor="otpInput"
                  sx={{
                    textAlign: "left",
                    fontSize: "14px",
                    marginTop: "3px",
                    ...(error && { color: "#E3022C" }),
                  }}
                >
                  {error.toString()}
                </InputLabel>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isButtonDisabled}
                sx={{
                  mt: 3,
                  mb: 2,
                  borderRadius: "25px",
                  fontSize: "16px",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                Confirm
              </Button>
              <Box alignItems="center">
                If you didn&apos;t receive a code you can try resending one{" "}
                <Timer handleResendCode={() => handleResendCode()} />
              </Box>
            </Box>
          </Box>
        </>
      }
    />
  );
}
