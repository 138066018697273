import React from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useUserContext } from "../../contexts/user-context";
import { Paths } from "../../types/app";
import { useNavigate } from "react-router";
import { logout } from "../../services/cognito";

type HeaderProps = {
  emailId?: string;
};

export const Header: React.FC<HeaderProps> = ({ emailId }) => {
  const userContext = useUserContext();
  const navigate = useNavigate();

  const logoutUser = async () => {
    await logout(userContext.refreshToken!);
    userContext.clear();
    navigate(Paths.LOGIN);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        color: "#4B5563",
      }}
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        {emailId ? (
          <Typography variant="body2" align="center" color={"#4B5563"}>
            {emailId}
            <IconButton
              size="medium"
              aria-label="logout button"
              onClick={() => logoutUser()}
              sx={{
                color: "#9CA3AF",
                border: "1px solid",
                marginLeft: "10px",
              }}
            >
              <LogoutIcon aria-label="logout icon" />
            </IconButton>
          </Typography>
        ) : (
          <Typography variant="body2" align="center">
            Already have an account?{" "}
            <Button
              variant="text"
              href="/"
              sx={{
                border: "1px solid var(--gray-300, #E5E7EB)",
                borderRadius: "25px",
                mx: 1,
              }}
            >
              Log in{" "}
            </Button>
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
};
