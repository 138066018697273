import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SidePanel } from "../../components/SideInfoPanel";
import { TwoColumnLayout } from "../../components/TwoColumnLayout";
import { InputLabel } from "@mui/material";
import { BootstrapInput } from "../../components/BootstrapInput";
import { useLocation, useNavigate } from "react-router";
import { AppError, LoginError, Paths } from "../../types/app";
import { sendCode } from "../../services/cognito";
import { UserNotFoundException } from "@aws-sdk/client-cognito-identity-provider";
import { useError } from "../../contexts/ErrorContext";
import { useUserContext } from "../../contexts/user-context";
import { hasTokenExpired } from "../../utils/util";

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export default function Login() {
  const [error, setError] = useState<LoginError>();
  const [emailId, setEmail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { accessToken } = useUserContext();
  const { handleError } = useError();

  const { redirectTo } = location.state || {};

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const { Session } = await sendCode(emailId);
      navigate(Paths.CONFIRM_EMAIL, {
        state: { emailId, Session, redirectTo },
      });
    } catch (e) {
      if (e instanceof UserNotFoundException) {
        setError(LoginError.USER_NOT_FOUND);
      } else {
        const error = e as AppError;
        if (error && error.message) {
          handleError(error.message);
        }
      }
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;

    if (validateEmail(newEmail)) {
      setIsButtonDisabled(false);
      setEmail(newEmail);
    } else {
      setIsButtonDisabled(true);
    }
  };

  useEffect(() => {
    if (accessToken) {
      if (!hasTokenExpired(accessToken)) {
        navigate(Paths.ACTION_PLAN_HOME_PAGE);
      }
    }
  });

  return (
    <TwoColumnLayout
      leftChild={<SidePanel />}
      rightChild={
        <Box
          sx={{
            my: { xl: 30, l: 30, md: 30, sm: 29, xs: 15 },
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            sx={{ fontWeight: 500, mb: 2 }}
          >
            Login
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              mt: 1,
              width: { xl: 480, lg: 480, md: 480, sm: 370, xs: 345 },
            }}
          >
            <InputLabel
              htmlFor="email"
              sx={{
                textAlign: "left",
                fontSize: "14px",
                marginBottom: "3px",
                ...(error && { color: "#E3022C" }),
              }}
            >
              Your work email
            </InputLabel>
            <BootstrapInput
              required
              id="email"
              fullWidth
              name="emailId"
              autoComplete="email"
              autoFocus
              error={!!error}
              onChange={handleEmailChange}
            />
            {error && (
              <InputLabel
                htmlFor="email"
                sx={{
                  textAlign: "left",
                  fontSize: "14px",
                  marginTop: "3px",
                  ...(error && { color: "#E3022C" }),
                }}
              >
                {error.toString()}
              </InputLabel>
            )}
            <Button
              role="button"
              type="submit"
              fullWidth
              variant="contained"
              disabled={isButtonDisabled}
              sx={{
                mt: 3,
                mb: 2,
                borderRadius: "25px",
                fontSize: "16px",
                pt: 1.5,
                pb: 1.5,
              }}
            >
              Continue
            </Button>
            <Typography variant="body2" align="center">
              Don't have an account?{" "}
              <Button
                variant="text"
                href="/register"
                sx={{
                  display: "inline",
                  fontWeight: "700",
                }}
              >
                Sign Up{" "}
              </Button>
            </Typography>
          </Box>
        </Box>
      }
    />
  );
}
