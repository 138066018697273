import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from "@mui/material";

export const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number },
) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", flex: 3 }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          sx={{
            backgroundColor: "#F3EEE7",
            "&.MuiLinearProgress-root": {
              borderRadius: "8px",
              height: "6px",
            },
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#FBBF24",
            },
          }}
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{
            fontSize: "13px",
            fontWeight: 600,
          }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};
