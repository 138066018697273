import {
  AuthFlowType,
  InitiateAuthCommand,
  InitiateAuthCommandInput,
  CognitoIdentityProviderClient,
  RespondToAuthChallengeCommandInput,
  ChallengeNameType,
  RespondToAuthChallengeCommand,
  SignUpCommand,
  SignUpCommandInput,
  InitiateAuthCommandOutput,
  RevokeTokenCommand,
  RevokeTokenRequest,
} from "@aws-sdk/client-cognito-identity-provider";
import { config } from "../configs/app-config";

const cognitoClient = new CognitoIdentityProviderClient({
  region: config?.cognito.region,
});

export const sendCode = (email: string) => {
  const input: InitiateAuthCommandInput = {
    ClientId: config?.cognito.clientId,
    AuthFlow: AuthFlowType.CUSTOM_AUTH,
    AuthParameters: {
      USERNAME: email,
    },
  };
  const command = new InitiateAuthCommand(input);
  return cognitoClient.send(command);
};

export const verifyLogin = async (
  code: string,
  email: string,
  session: string,
) => {
  const input: RespondToAuthChallengeCommandInput = {
    ClientId: config?.cognito.clientId,
    ChallengeName: ChallengeNameType.CUSTOM_CHALLENGE,
    Session: session,
    ChallengeResponses: {
      ANSWER: code,
      USERNAME: email,
    },
  };
  const command = new RespondToAuthChallengeCommand(input);
  return await cognitoClient.send(command);
};

function intToHex(nr: number) {
  return nr.toString(16).padStart(2, "0");
}

function getRandomString(bytes: number) {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues)
    .map(intToHex)
    .map((hex) => (Math.random() > 0.5 ? hex.toUpperCase() : hex))
    .join("");
}

export const signUp = async (email: string) => {
  const input: SignUpCommandInput = {
    Password: getRandomString(30),
    Username: email,
    ClientId: config?.cognito.clientId,
    UserAttributes: [{ Name: "email", Value: email }],
  };
  const command = new SignUpCommand(input);
  return await cognitoClient.send(command);
};

export const refreshAccessToken = async (
  refreshToken: string,
): Promise<InitiateAuthCommandOutput> => {
  const payload: InitiateAuthCommandInput = {
    AuthFlow: "REFRESH_TOKEN_AUTH",
    AuthParameters: {
      REFRESH_TOKEN: refreshToken,
    },
    ClientId: config?.cognito.clientId,
  };
  const command = new InitiateAuthCommand(payload);
  return cognitoClient.send(command);
};

export const logout = async (token: string) => {
  const input: RevokeTokenRequest = {
    Token: token, // refresh token
    ClientId: config?.cognito.clientId,
  };
  const command = new RevokeTokenCommand(input);
  return await cognitoClient.send(command);
};
