import { useState } from "react";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SidePanel } from "../../components/SideInfoPanel";
import { TwoColumnLayout } from "../../components/TwoColumnLayout";
import { Grid, Link, MenuItem, SelectChangeEvent } from "@mui/material";
import { BootstrapInput } from "../../components/BootstrapInput";
import { useLocation, useNavigate } from "react-router";
import { AppError, LoginError, Paths } from "../../types/app";
import { IDecodeToken, IUserFormData } from "../../types/user";
import { sendCode, signUp } from "../../services/cognito";
import { UsernameExistsException } from "@aws-sdk/client-cognito-identity-provider";
import { Label } from "../../components/Label";
import { validationSchema } from "./validation-schema";
import { Header } from "../../components/Header";
import dropdownOptions from "./dropdown.json";
import freeEmailDomains from "free-email-domains";
import { StyledSelect } from "../../components/StyledSelect";
import { useError } from "../../contexts/ErrorContext";
import { jwtDecode } from "jwt-decode";
import { timestampExpired } from "../../utils/util";

export default function RegisterUser() {
  const { search } = useLocation();
  const token = new URLSearchParams(search).get("token");
  const decodedInvitation = token ? jwtDecode<IDecodeToken>(token) : null;

  const hearAboutUsOptions = dropdownOptions.hearAboutUs;
  const [errors, setErrors] = useState<Partial<IUserFormData>>({});
  const [formData, setFormData] = useState<IUserFormData>({
    firstName: "",
    lastName: "",
    emailId: decodedInvitation ? decodedInvitation.email : "",
    role: "",
    hearAboutUs: "",
  });
  const navigate = useNavigate();
  const { handleError } = useError();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isValid = await validate();
    if (decodedInvitation?.exp && timestampExpired(decodedInvitation?.exp)) {
      setErrors({ emailId: LoginError.TOKEN_EXPIRED });
      return;
    }
    if (isValid && isCompanyDomain()) {
      const { emailId } = formData;
      try {
        await signUp(emailId);
        const { Session } = await sendCode(emailId);
        navigate(Paths.CONFIRM_EMAIL, {
          state: { emailId, Session, formData, token },
        });
      } catch (e) {
        if (e instanceof UsernameExistsException) {
          setErrors({ emailId: LoginError.USER_EXISTS });
        } else {
          const error = e as AppError;
          if (error && error.message) {
            handleError(error.message);
          }
        }
      }
    }
  };

  const isCompanyDomain = () => {
    //TODO: remove it later
    if (
      process.env.REACT_APP_ENV === "local" ||
      process.env.REACT_APP_ENV === "dev"
    ) {
      return true;
    }
    const domain = formData.emailId.split("@")[1];
    if (freeEmailDomains.includes(domain)) {
      setErrors({ emailId: LoginError.WORK_EMAIL });
      return false;
    }
    return true;
  };

  const validate = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors: Partial<IUserFormData> = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path as keyof IUserFormData] = error.message;
        });
      }
      setErrors(validationErrors);
      return false;
    }
  };

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<unknown>,
  ) => {
    const { name, value } = event.target;
    const newFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(newFormData);
  };

  return (
    <TwoColumnLayout
      leftChild={<SidePanel />}
      rightChild={
        <>
          <Header />
          <Box
            sx={{
              my: { xl: 17, l: 17, md: 17, sm: 15, xs: 10 },
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h4"
              sx={{ fontWeight: 500, mb: 2 }}
            >
              Create your account
            </Typography>
            <Typography gutterBottom>
              Please fill in your details and start tracking your progress
              today!
            </Typography>
            {decodedInvitation && decodedInvitation?.company_name ? (
              <Typography
                gutterBottom
                sx={{
                  color: "#fbbf24",
                  fontWeight: "600",
                }}
              >
                You are signing up as part of {decodedInvitation?.company_name}
              </Typography>
            ) : null}

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{
                mt: { xl: 4, sm: 1 },
                width: { xl: 480, lg: 480, md: 480, sm: 450, xs: 345 },
              }}
            >
              <Grid container spacing={3}>
                <Grid container item xs={6}>
                  <Label
                    htmlFor="firstName"
                    label="First name"
                    error={errors.firstName}
                  />
                  <BootstrapInput
                    required
                    id="firstName"
                    fullWidth
                    name="firstName"
                    autoComplete="firstName"
                    autoFocus
                    error={!!errors.firstName}
                    onChange={handleChange}
                  />
                  {errors.firstName && (
                    <Label
                      htmlFor="firstName"
                      error={errors.firstName}
                      label={errors.firstName.toString()}
                    />
                  )}
                </Grid>
                <Grid container item xs={6}>
                  <Label
                    htmlFor="lastName"
                    label="Last name"
                    error={errors.lastName}
                  />
                  <BootstrapInput
                    required
                    id="lastName"
                    fullWidth
                    name="lastName"
                    autoComplete="lastName"
                    error={!!errors.lastName}
                    onChange={handleChange}
                  />
                  {errors.lastName && (
                    <Label
                      htmlFor="lastName"
                      error={errors.lastName}
                      label={errors.lastName.toString()}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Label
                    htmlFor="email"
                    label="Your work email"
                    error={errors.emailId}
                  />
                  <BootstrapInput
                    required
                    id="email"
                    fullWidth
                    name="emailId"
                    autoComplete="email"
                    error={!!errors.emailId}
                    onChange={handleChange}
                    value={formData.emailId}
                    disabled={decodedInvitation?.email ? true : false}
                  />
                  <Typography variant="body2">
                    Please use your work email. We will send a login link every
                    time you try to log in
                  </Typography>
                  {errors.emailId && (
                    <Label
                      htmlFor="email"
                      error={errors.emailId}
                      label={errors.emailId.toString()}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Label
                    htmlFor="role"
                    label="What is your role?"
                    error={errors.role}
                  />
                  <BootstrapInput
                    required
                    id="role"
                    fullWidth
                    name="role"
                    autoComplete="role"
                    error={!!errors.role}
                    onChange={handleChange}
                  />
                  {errors.role && (
                    <Label
                      htmlFor="role"
                      error={errors.role}
                      label={errors.role.toString()}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Label
                    id="hearAboutUs-label"
                    htmlFor="hearAboutUs"
                    label="How did you hear about us?"
                  />
                  <StyledSelect
                    aria-labelledby="hearAboutUs-label"
                    labelId="hearAboutUs"
                    id="hearAboutUs"
                    name="hearAboutUs"
                    value={formData.hearAboutUs}
                    fullWidth
                    error={!!errors.hearAboutUs}
                    onChange={handleChange}
                    inputProps={{
                      "aria-labelledby": "hearAboutUs",
                      "data-testid": "hearAboutUs-label",
                    }}
                  >
                    {hearAboutUsOptions.map((option, i) => (
                      <MenuItem value={option} key={i}>
                        {option}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                  {errors.hearAboutUs && (
                    <Label
                      htmlFor="hearAboutUs"
                      error={errors.hearAboutUs}
                      label={errors.hearAboutUs.toString()}
                    />
                  )}
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                role="button"
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  borderRadius: "25px",
                  fontSize: "16px",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                Create your T-EDI Standards Account
              </Button>
              <Typography variant="body2">
                By confirming you email you agree to our{" "}
                <Link
                  href="https://www.tedistandards.com/terms"
                  underline="always"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  User Terms of Service{" "}
                </Link>
                and{" "}
                <Link
                  href="https://www.tedistandards.com/terms"
                  underline="always"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Privacy Policy.
                </Link>
              </Typography>
            </Box>
          </Box>
        </>
      }
    />
  );
}
