import { Box, Button, Grid, Typography } from "@mui/material";
import {
  getActionItems,
  getScore,
  markActionItem,
} from "../../services/standards-service";
import {
  ActionItemType,
  IActionItems,
  LevelScore,
  mapLevel,
} from "../../types/action-item";
import { AppError } from "../../types/app";
import { useError } from "../../contexts/ErrorContext";
import { useParams } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { useUserContext } from "../../contexts/user-context";
import ReplayIcon from "@mui/icons-material/Replay";
import { ActionsCard } from "../../components/ActionPlanCards";
import confetti from "canvas-confetti";
import { ActionPlanHeader } from "../../components/ActionPlanHeader";
import { IStandards } from "../../types/standard";
import { useStandards } from "../../contexts/StandardsProvider";

export default function ActionPlan() {
  const [standard, setStandard] = useState<IStandards | undefined>(undefined);
  const [actionItems, setActionItems] = useState<ActionItemType>({
    actionItemsCompleted: [],
    actionItemsToDo: [],
  });
  const [levelsScore, setLevelsScore] = useState<LevelScore>({});

  const { id } = useParams();
  const { refreshData } = useStandards();
  const userContext = useUserContext();
  const { handleError } = useError();

  const getStandardScore = useCallback(async () => {
    if (id) {
      try {
        const eachStandard = await getScore(parseInt(id));
        setStandard(eachStandard);
        userContext.set({
          selectedStandard: eachStandard,
        });
      } catch (e) {
        const error = e as AppError;
        if (error && error.message) {
          handleError(error.message);
        }
      }
    }
  }, [id, userContext, handleError]);

  const getActionPlan = useCallback(async () => {
    if (id) {
      try {
        const actionItems = await getActionItems(parseInt(id));
        const completedActionItem: IActionItems[] = [];
        const incompleteActionItem: IActionItems[] = [];
        const levelCounts: LevelScore = {};
        actionItems.forEach((eachActionItem) => {
          const { standardLevel } = eachActionItem;
          if (!levelCounts[mapLevel[standardLevel]]) {
            levelCounts[mapLevel[standardLevel]] = { total: 0, completed: 0 };
          }
          if (eachActionItem.done) {
            completedActionItem.push(eachActionItem);
            levelCounts[mapLevel[standardLevel]]!.completed += 1;
          } else {
            incompleteActionItem.push(eachActionItem);
          }
          levelCounts[mapLevel[standardLevel]]!.total += 1;
        });
        setActionItems({
          actionItemsCompleted: completedActionItem,
          actionItemsToDo: incompleteActionItem,
        });
        setLevelsScore(levelCounts);
      } catch (e) {
        const error = e as AppError;
        if (error && error.message) {
          handleError(error.message);
        }
      }
    }
  }, [id, handleError]);

  useEffect(() => {
    getStandardScore();
    getActionPlan();
    // eslint-disable-next-line
  }, [id]);

  const toggleActionItem = async (id: number, isDone: boolean) => {
    try {
      await markActionItem(id, isDone);
      if (isDone) {
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 },
        });
      }
      getStandardScore();
      getActionPlan();
      refreshData();
    } catch (e) {
      const error = e as AppError;
      if (error && error.message) {
        handleError(error.message);
      }
    }
  };

  return (
    <Box component="main" sx={{ flex: 1 }}>
      <ActionPlanHeader
        standard={standard}
        levelsScore={levelsScore}
        getStandardScore={getStandardScore}
      />
      <Grid
        container
        className="action-item"
        sx={{
          padding: "20px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="h3"
            sx={{
              marginBottom: "20px",
              marginTop: "20px",
              fontSize: "24px",
            }}
          >
            Action Plan ({actionItems.actionItemsToDo.length})
          </Typography>
          {actionItems.actionItemsToDo.map((actionItem, i) => (
            <ActionsCard
              actionItem={actionItem}
              isActionPlan={true}
              key={i}
              ButtonComponent={
                <>
                  <Button
                    role="button"
                    variant="contained"
                    onClick={() => toggleActionItem(actionItem.id, true)}
                    sx={{
                      padding: "7px 28px",
                      borderRadius: "20px",
                      fontSize: "16px",
                      boxShadow: "none",
                      marginBottom: "10px",
                    }}
                  >
                    Mark Done
                  </Button>
                </>
              }
            />
          ))}
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="h3"
            sx={{
              marginBottom: "20px",
              marginTop: "20px",
              fontSize: "24px",
            }}
          >
            Action Marked Done ({actionItems.actionItemsCompleted.length})
          </Typography>
          {actionItems.actionItemsCompleted.map((actionItem, i) => (
            <ActionsCard
              actionItem={actionItem}
              isActionPlan={false}
              key={i}
              ButtonComponent={
                <>
                  <Button
                    role="button"
                    variant="contained"
                    onClick={() => toggleActionItem(actionItem.id, false)}
                    sx={{
                      borderRadius: "25px",
                      fontSize: "16px",
                      padding: "7px 36px",
                      background: "#fff",
                      boxShadow: "none",
                      color: "#374151",
                      marginBottom: "10px",
                      "&:hover": {
                        background: "#E5E7EB",
                        borderColor: "#6B7280",
                        boxShadow: "none",
                      },
                    }}
                    startIcon={<ReplayIcon />}
                  >
                    Undo
                  </Button>
                </>
              }
            />
          ))}
        </Box>
      </Grid>
    </Box>
  );
}
