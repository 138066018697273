import * as Yup from "yup";
import { isEmail } from "validator";

export const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  emailId: Yup.string()
    .email("Invalid email id format")
    .required("Email id is required")
    .test(
      "is-valid",
      () => `Email id format is invalid`,
      (value) =>
        value
          ? isEmail(value)
          : new Yup.ValidationError("Email id format is invalid"),
    ),
  role: Yup.string().required("Role is required"),
  hearAboutUs: Yup.string().required("Hear about us is required"),
});
