import { AuthenticationResultType } from "@aws-sdk/client-cognito-identity-provider";
import { getUserFromToken, hasProps } from "../utils/util";
import { ApiClient } from "./api-client";
import { AppException } from "../exceptions/exceptions";
import { CommonErrorCodes } from "../errors/common-error-codes";
import {
  CompanyFormData,
  IInviteUser,
  IUser,
  IUserFormData,
} from "../types/user";

export const getUser = async (cognitoId: string): Promise<IUser> => {
  const client = ApiClient.getInstance();
  try {
    const response = await client.get("/users", { cognitoId });
    return response.data as IUser;
  } catch (e) {
    throw new AppException("User not found", CommonErrorCodes.NOT_FOUND);
  }
};

export const createUser = async (
  userData: IUserFormData & { cognitoId: string } & {
    invitationToken?: string | null;
  },
): Promise<IUser> => {
  const client = ApiClient.getInstance();
  try {
    const response = await client.post("/users", userData);
    return response.data as IUser;
  } catch (e) {
    throw new AppException(
      "Error creating user. Please try logging in instead.",
      CommonErrorCodes.API_ERROR,
    );
  }
};

export const getOrCreateUser = async (
  cognitoAuthResult: AuthenticationResultType,
  formData?: IUserFormData,
  token?: string | null,
): Promise<IUser> => {
  const payload = getUserFromToken(cognitoAuthResult.IdToken!);

  if (!payload.sub) {
    throw new AppException("no id token", CommonErrorCodes.INCORRECT_STATE);
  }
  try {
    return await getUser(payload.sub);
  } catch (e) {
    if (
      e instanceof AppException &&
      e.code === CommonErrorCodes.NOT_FOUND &&
      formData
    ) {
      return createUser({
        ...formData,
        cognitoId: payload.sub,
        invitationToken: token,
      });
    } else {
      throw new AppException(
        "An error has occurred. Please try again.",
        CommonErrorCodes.API_ERROR,
      );
    }
  }
};

export const linkUserCompany = async (
  userId: number,
  company: CompanyFormData,
): Promise<IUser> => {
  const client = ApiClient.getInstance();
  try {
    const response = await client.post(`/user-org/${userId}`, {
      name: company.companyName,
      size: company.companySize,
      industry: company.industry,
    });
    return response.data as IUser;
  } catch (e) {
    throw new AppException(
      "There was an error adding organisation details. Please try again.",
      CommonErrorCodes.API_ERROR,
    );
  }
};

export const sendInvite = async (emailId: string): Promise<IInviteUser> => {
  const client = ApiClient.getInstance();
  try {
    const response = await client.post("/invites", {
      emailId: emailId,
    });
    return response.data as IInviteUser;
  } catch (e) {
    if (hasProps(e, "code")) {
      if (e.code === "400")
        throw new AppException(
          "Cannot invite this user. The user is already registered.",
          CommonErrorCodes.API_ERROR,
        );
    }
    throw new AppException(
      "Error sending the invite. Please try again.",
      CommonErrorCodes.API_ERROR,
    );
  }
};
