import { alpha, styled } from "@mui/material/styles";
import { MuiOtpInput, MuiOtpInputProps } from "mui-one-time-password-input";

interface OtpInputProps extends MuiOtpInputProps {
  error?: boolean;
}

export const OtpInput = styled(MuiOtpInput)<OtpInputProps>(
  ({ theme, error }) => ({
    ...(error && {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid",
        borderColor: `${alpha(theme.palette.mode === "light" ? "#E3022C" : "#FF4E6F", 0.5)}`,
        "&:hover": {
          borderColor: `${alpha(theme.palette.mode === "light" ? "#E3022C" : "#FF4E6F", 0.5)}`,
        },
      },
    }),
    ".MuiOtpInput-TextField .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FAAA0A",
    },
  }),
);
